
.top-section{
    display: flex;
    justify-content: space-between;
}
.top-subsection{
    display: flex;
}
.top-subsection__links{
    padding-right: 1.5vh;
    font-weight: 400;

}
.modal-exit{
    padding-right: 1.5vh;
    font-weight: 400;
    text-align: right;
}
.Name{
    font-weight: bold;
    font-style: italic;
}
.bottom-section{
    display: flex;
    background-color: black;
}
.bottom-item:hover{
    background-color: rgb(99, 99, 99);
}
.bottom-item {
    padding: 10px 15px;
    color: white;
    border-right: 1px solid white;
}
.Navbar {
    border-bottom: 1px solid black;
    width: 90%;
    margin: auto;
}
.top-subsection a{
    color: black;
    text-decoration: none;
}
.mobile{
    display: none;
}
.ham{
    width: 2em;
}
.modal{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    height: 20000px;
    background-color: black;
    color: white;
}
.modal a{
    color: white;
}
.desktop{
    display: flex;
}
@media only screen and (max-width: 900px) {
    .desktop{
      display: none;
    }
    .mobile{
        display: block;
        margin: auto;
    }
    .Navbar{
        position: fixed;
        width: 90%;
        background-color: #FFF2E5;
        z-index: 3;
        left: 0;
        right: 0;
        padding: 0  5vw;
    }
  }