.welcome {
  font-size: 1.5em;
  width: 50vh;
  margin-top: 2em;
}
.hero_img {
  mix-blend-mode: multiply;
  width: 35vh;
  margin-top: 2em;
}
.hero {
  /*margin-top: 3em;*/
  background-color: #fff2e5;
}
.hero_subsection {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2em;
}

.arrow {
  /*padding: 2em;*/
  width: 5vh;
}
@media only screen and (max-width: 850px) {
  .hero_subsection {
    flex-direction: column;
  }
  .welcome {
    width: 80vw;
  }
}
@media only screen and (max-width: 900px) {
  .hero {
    padding-top: 2em;
  }
}
